import {
    VStack,
    Button,
    HStack,
    Spacer,
    Text,
    Image,
    Link,
    useToast,
    Spinner
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import google from '../../assets/google.png';
import { FaApple } from 'react-icons/fa';
import { useState } from 'react';
import {
    AuthErrorCodes,
    GoogleAuthProvider,
    signInWithPopup,
    OAuthProvider
} from '@firebase/auth';
import { auth } from '../../shared/auth/firebaseConfig';
import { logger } from '../../shared/utility/logger';
import { getMessageFromCode } from '../../shared/auth/firebaseErrorMessages';
import React from 'react';
import { HELP_GUIDE_URL } from '../../shared/utility/constants';

const TAG = 'LoginMethod';

interface LoginMethodProps {
    handleSignInWithEmailBtn: () => void;
    handleSignInWithPhoneBtn: () => void;
    // authSuccess: () => Promise<void>;
}
export default function LoginMethod({
    handleSignInWithEmailBtn,
    handleSignInWithPhoneBtn
}: // authSuccess
Readonly<LoginMethodProps>) {
    // #region business-logic
    const toast = useToast();
    const [disableBtns, setDisableBtns] = useState(false);
    const [googleSubmitting, setGoogleSubmitting] = useState(false);
    const [appleSubmitting, setAppleSubmitting] = useState(false);

    async function handleGoogleSignInBtn() {
        setGoogleSubmitting(true);

        // wait 1 second before enabling buttons so user can select login method if cancels popup
        setTimeout(() => {
            setGoogleSubmitting(false);
        }, 1000);

        const provider = new GoogleAuthProvider();
        // need this in order to allow user to be able to reselect which gmail account they want to user, instead of automatically signing in with the last one used: https://stackoverflow.com/a/77340420
        provider.setCustomParameters({ prompt: 'select_account' });
        await oauthSignIn(provider);

        setDisableBtns(false);
    }

    async function handleAppleSignInBtn() {
        setAppleSubmitting(true);

        // wait 1 second before enabling buttons so user can select login method if cancels popup
        setTimeout(() => {
            setAppleSubmitting(false);
        }, 1000);

        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        await oauthSignIn(provider);

        setAppleSubmitting(false);
    }

    async function oauthSignIn(provider: GoogleAuthProvider | OAuthProvider) {
        setDisableBtns(true);

        // wait 1 second before enabling buttons so user can select login method if cancels popup
        setTimeout(() => {
            setDisableBtns(false);
        }, 1000);

        try {
            const result = await signInWithPopup(auth, provider);
            const { user } = result;
            logger.info(TAG, 'user', user);

            try {
                // if GoogleAuthProvider, then set loading to true
                if (provider instanceof GoogleAuthProvider) {
                    setGoogleSubmitting(true);
                } else {
                    setAppleSubmitting(true);
                }
                setDisableBtns(true);

                // await authSuccess();
            } catch (err: any) {
                logger.info(TAG, err);
                toast({
                    description: err.message,
                    status: 'error',
                    variant: 'top-accent',
                    isClosable: true
                });
            }

            setGoogleSubmitting(false);
            setAppleSubmitting(false);
        } catch (err: any) {
            logger.info(TAG, err);
            // ignore user cancel errors
            if (
                err.code != AuthErrorCodes.EXPIRED_POPUP_REQUEST &&
                err.code != AuthErrorCodes.USER_CANCELLED &&
                err.code != AuthErrorCodes.POPUP_CLOSED_BY_USER
            ) {
                const message = getMessageFromCode(err.code);
                toast({
                    description: message,
                    status: 'error',
                    variant: 'top-accent',
                    isClosable: true
                });
            }
        }

        setDisableBtns(false);
    }
    // #endregion

    return (
        <VStack spacing={10} className="fillMaxWidth" minHeight="inherit">
            <VStack spacing={5} className="fillMaxWidth">
                <Button
                    data-testid="emailBtn"
                    w="100%"
                    border="1px"
                    borderColor="#e2e2e2"
                    background="white"
                    leftIcon={<EmailIcon fontSize={25} color="gray" ms={-2} />}
                    size={'lg'}
                    fontWeight="normal"
                    onClick={handleSignInWithEmailBtn}
                    fontSize={16}
                    isDisabled={disableBtns}
                >
                    <Text className="loginMethodBtn">Continue with email</Text>
                </Button>

                <Button
                    w="100%"
                    border="1px"
                    borderColor="#e2e2e2"
                    background="white"
                    leftIcon={<PhoneIcon fontSize={25} color="gray" ms={-2} />}
                    size={'lg'}
                    fontWeight="normal"
                    onClick={handleSignInWithPhoneBtn}
                    fontSize={16}
                    isDisabled={disableBtns}
                >
                    <Text className="loginMethodBtn">Continue with phone</Text>
                </Button>

                <Button
                    w="100%"
                    border="1px"
                    borderColor="#e2e2e2"
                    background="white"
                    leftIcon={
                        <HStack ms={-2}>
                            <Image
                                src={google}
                                boxSize="22px"
                                objectFit="cover"
                            />
                        </HStack>
                    }
                    size={'lg'}
                    fontWeight="normal"
                    fontSize={16}
                    onClick={handleGoogleSignInBtn}
                    isLoading={googleSubmitting}
                    loadingText={`Continue with Google`}
                    spinner={<Spinner size="sm" />}
                    isDisabled={disableBtns}
                >
                    <Text className="loginMethodBtn">Continue with Google</Text>
                </Button>

                <Button
                    w="100%"
                    border="1px"
                    borderColor="#e2e2e2"
                    background="white"
                    leftIcon={
                        <HStack ms={-2.5}>
                            <FaApple fontSize={25} />
                        </HStack>
                    }
                    size={'lg'}
                    fontWeight="normal"
                    fontSize={16}
                    onClick={handleAppleSignInBtn}
                    isLoading={appleSubmitting}
                    loadingText={`Continue with Apple`}
                    spinner={<Spinner size="sm" />}
                    isDisabled={disableBtns}
                >
                    <Text className="loginMethodBtn">Continue with Apple</Text>
                </Button>
            </VStack>

            <Spacer />

            <Text fontSize={13} textColor="#848484">
                By continuing, you agree to Caraplug's{' '}
                <Link
                    color="#484848"
                    href={`${HELP_GUIDE_URL}/terms-of-service`}
                    target="_blank"
                >
                    Terms of Service
                </Link>{' '}
                and confirm that you have read Caraplug's{' '}
                <Link
                    color="#484848"
                    href={`${HELP_GUIDE_URL}/privacy-policy`}
                    target="_blank"
                >
                    Privacy Policy
                </Link>
                .
            </Text>
        </VStack>
    );
}
