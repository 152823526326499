import { Box, Button, Container, Heading, Text } from '@chakra-ui/react';

import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';

import { logger } from '../shared/utility/logger';
import React from 'react';
const TAG = 'Home';

export default function Home() {
    const dispatch = useDispatch<AppDispatch>();
    const session = useSelector((state: RootState) => state.session);

    useEffect(() => {
        const token = session.token;
        logger.info(TAG, 'token:', token);
    }, [session]);

    const goToLogin = (e: any) => {
        e.preventDefault();
        window.location.href = '/login';
    };

    return (
        <Container padding={8}>
            <Heading fontWeight={300} as="h2" size="xl" textAlign="center">
                Hello from
                <br />
                <Text as="span" color="green.400">
                    Caraplug
                </Text>
            </Heading>

            <Box
                mt="4"
                bg="white"
                boxShadow="xl"
                p="4"
                rounded="md"
                textAlign="center"
            >
                <Button data-testid="homeLoginBtn" onClick={goToLogin}>
                    Login
                </Button>
            </Box>
        </Container>
    );
}
