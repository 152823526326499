import {
    FormControl,
    FormErrorMessage,
    Text,
    HStack,
    PinInput,
    PinInputField,
    VStack,
    Alert,
    AlertIcon,
    useToast,
    Center,
    Spacer,
    Flex,
    Grid,
    useMediaQuery
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import 'react-phone-input-2/lib/material.css';
import { useEffect, useState } from 'react';
import '@styles/login.css';
import { UserLink } from './Components';

// redux

import { logger } from '../../shared/utility/logger';
import { getMessageFromCode } from '../../shared/auth/firebaseErrorMessages';
import { auth } from '@root/src/shared/auth/firebaseConfig';
import { signInWithPhoneNumber } from '@firebase/auth';
import React from 'react';
const TAG = 'Login';

interface VerifyPhoneCodeProps {
    phoneCodeShowing: boolean;
    currentPhone: React.MutableRefObject<string>;
    resetCaptcha: () => void;
    // authSuccess: () => Promise<void>;
}
export default function VerifyPhoneCode({
    phoneCodeShowing,
    currentPhone,
    resetCaptcha
}: // authSuccess
Readonly<VerifyPhoneCodeProps>) {
    // #region business-logic
    const toast = useToast();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [showCodeError, setShowCodeError] = useState(false);
    const [allowResendCode, setAllowResendCode] = useState(false);
    const minSecondsBetweenResendCode = 5;
    const [secondsTilAllowResendCode, setSecondsTilAllowResendCode] =
        useState(0);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (phoneCodeShowing) {
            // focus on code input
            document.getElementById('phoneCode-0')?.focus();
            // reset errors
            setCodeError('');
            setShowCodeError(false);
            // reset form
            setCode('');

            startResendCodeTimer();
        }
    }, [phoneCodeShowing]);

    const validCode = (phoneCode: string) => {
        return phoneCode.length === 6;
    };

    const handlePhoneCodeSubmit = async (code: string) => {
        if (submitting) {
            return;
        }

        const codeError = !validCode(code);
        if (codeError) {
            setCodeError('Please enter a valid code');
            document.getElementById('phoneCode-0')?.focus();
        }
        setShowCodeError(codeError);

        const confirmationResultError = window.confirmationResult == null;
        if (confirmationResultError) {
            setCodeError(
                'Confirmation result is null. Please refresh the page.'
            );
        }
        setShowCodeError(confirmationResultError);

        if (codeError || confirmationResultError) {
            return;
        }

        setSubmitting(true);
        try {
            await new Promise<void>((resolve, reject) => {
                window.confirmationResult
                    ?.confirm(code)
                    .then((result) => {
                        // User signed in successfully.
                        const { user } = result;
                        logger.info(TAG, 'user:', user);
                        resolve();
                    })
                    .catch((error) => {
                        // User couldn't sign in (bad verification code?)
                        // ...
                        console.log('error:', error);
                        reject(error);
                    });
            });

            // try {
            //     await authSuccess();
            // } catch (err: any) {
            //     logger.info(TAG, err);
            //     setCodeError(err.message);
            //     setShowCodeError(true);
            // }
        } catch (error: any) {
            logger.info(TAG, error);
            const message = getMessageFromCode(error.code);
            setCodeError(message);
            setShowCodeError(true);
        }

        setSubmitting(false);

        // set focus on code input (NOTE: need a slight delay for focus to work & must be after submitting is false)
        setTimeout(() => {
            document.getElementById('phoneCode-0')?.focus();
        }, 100);

        // clear the code
        setCode('');
    };

    function startResendCodeTimer() {
        setSecondsTilAllowResendCode(minSecondsBetweenResendCode);

        const interval = setInterval(() => {
            console.log(
                'secondsTilAllowResendCode:',
                secondsTilAllowResendCode
            );
            setSecondsTilAllowResendCode((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    setAllowResendCode(true);
                    return minSecondsBetweenResendCode;
                }
                return prev - 1;
            });
        }, 1000);
        return interval;
    }

    const resendCode = async () => {
        if (!allowResendCode) {
            return;
        }

        setAllowResendCode(false);
        try {
            const finalPhone = `+${currentPhone.current}`;
            console.log('finalPhone:', finalPhone);
            const appVerifier = window.recaptchaVerifier;
            const result = await signInWithPhoneNumber(
                auth,
                finalPhone,
                appVerifier
            );
            window.confirmationResult = result;

            toast({
                description: `Code was sent to ${finalPhone}`,
                status: 'success',
                variant: 'top-accent',
                isClosable: true
            });

            // allow resending code after 10s
            startResendCodeTimer();
        } catch (error: any) {
            logger.info(TAG, error);
            resetCaptcha();
            setCode('');
            setAllowResendCode(true);
            const message = getMessageFromCode(error.code);
            toast({
                description: message,
                status: 'error',
                variant: 'top-accent',
                isClosable: true
            });
        }
    };
    // #endregion

    const [smallScreen] = useMediaQuery('(max-width: 600px)');
    const [mediumScreen] = useMediaQuery('(max-width: 800px)');
    const [largeScreen] = useMediaQuery('(max-width: 1200px)');

    return (
        <form>
            <VStack align="center" spacing={5}>
                <HStack spacing="12px" align={'center'} w="full">
                    <PhoneIcon fontSize={25} />
                    <Text>Verification Code</Text>
                    <Spacer />
                </HStack>

                <Alert
                    status="info"
                    borderRadius={10}
                    alignItems="flex-start"
                    textAlign="left"
                >
                    <AlertIcon />A verification code has been texted to{' '}
                    {currentPhone.current}. Enter the code that you received.
                </Alert>

                <Center>
                    <VStack>
                        <HStack>
                            <PinInput
                                id="phoneCode"
                                placeholder=""
                                otp={true}
                                size={
                                    smallScreen
                                        ? 'sm'
                                        : mediumScreen
                                        ? 'md'
                                        : 'lg'
                                }
                                type="number"
                                variant="filled"
                                value={code}
                                onChange={(value) => setCode(value)}
                                onComplete={handlePhoneCodeSubmit}
                                isDisabled={submitting}
                            >
                                <PinInputField
                                    background="white"
                                    _focus={{
                                        bg: 'white'
                                    }}
                                />
                                <PinInputField
                                    background="white"
                                    _focus={{
                                        bg: 'white'
                                    }}
                                />
                                <PinInputField
                                    background="white"
                                    _focus={{
                                        bg: 'white'
                                    }}
                                />
                                <PinInputField
                                    background="white"
                                    _focus={{
                                        bg: 'white'
                                    }}
                                />
                                <PinInputField
                                    background="white"
                                    _focus={{
                                        bg: 'white'
                                    }}
                                />
                                <PinInputField
                                    background="white"
                                    _focus={{
                                        bg: 'white'
                                    }}
                                />
                            </PinInput>
                        </HStack>

                        <Flex w="full" mt={1}>
                            <Spacer />
                            <UserLink
                                text={
                                    allowResendCode
                                        ? 'Resend code'
                                        : `Resend code in ${secondsTilAllowResendCode}s`
                                }
                                enableLink={allowResendCode && !submitting}
                                linkAction={resendCode}
                            />
                        </Flex>
                    </VStack>
                </Center>

                <FormControl isInvalid={showCodeError} mt={-4}>
                    <FormErrorMessage>
                        <Alert
                            status="error"
                            borderRadius={10}
                            alignItems="flex-start"
                            textAlign="left"
                        >
                            <AlertIcon mt={-0.5} />
                            <Text mt={0.5}>{codeError}</Text>
                        </Alert>
                    </FormErrorMessage>
                </FormControl>
            </VStack>
        </form>
    );
}
